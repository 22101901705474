<template>
  <div  v-if="AU_2_MCD_42 && isdesktop">
    <div class="DockNewOpen shadow" v-if="opendock">
      <div class="iconApp mt-3" @click="dialog=true">
          <span>Mes Comptes </span>
      
        <div :class="'d-flex justify-content-center align-items-center user-profile ' + (entreprise?' business-profile':' personal-profile')" style="border-radius:70Px;width:50px!important;height:50px;font-size: 35px;margin:0px;padding:0px ">{{ entreprise? entreprise.ete_name.charAt(0) :  user.usr_first_name.charAt(0) }}</div>
        </div>
          <div :class="mobile& entreprise!=null ?'mxSmarthopen':' d-flex flex-column  align-items-center'" >
          <div class="iconApp" @click="$router.push('/mymail')" >
          <span>Boite Mail</span>
          
          <img  style="border-radius:20px" width="45px" height="45px" :src="require('./assets/mail.png')"></img>

         </div>
         <div class="iconApp"  @click="$router.push('/mywebsite')" v-if="entreprise!=null">
          <span>Modulab.in</span>
          <img  style="border-radius:20px;" width="45px" height="45px" :src="require('./assets/MCDmodula.png')"></img>

         </div>
         <div class="iconApp" @click="$router.push('/Newsletter')" v-if="entreprise!=null">
          <span>Newsletter</span>
          <img  style="border-radius:20px" width="45px" height="45px" :src="require('./assets/IOS_newsletter.png')"></img>

         </div>

         <div class="iconApp" @click="$router.push('/pubads')" v-if="entreprise!=null">
          <span>MCD Ads</span>
          <img  style="border-radius:20px" width="45px" height="45px" :src="require('./assets/logopub.png')"></img>

         </div>
         </div>
       
        <div :class="mobile& entreprise!=null ?'mxSmarthopen':'d-flex flex-column  align-items-center'" >
         <div class="iconApp" @click="$router.push('/mcdtalk')">
          <span>MCD Talk</span>
          <img  style="border-radius:20px" width="45px" height="45px" :src="require('./assets/camera.png')"></img>

         </div>
         <div class="iconApp" @click="$router.push('/Dating')">
          <span>Follow-me.dating</span>
          <img  style="border-radius:20px" width="45px" height="45px" :src="require('./assets/logodate.png')"></img>

         </div>
         <div class="iconApp" @click="$router.push('/SocialWork')">
          <span>Pluméia</span>
          <img  style="border-radius:20px" width="45px" height="45px" :src="require('./assets/SocialWork.png')"></img>

         </div>
         <div class="iconApp" @click="$router.push('/mcdSchool')">
          <span>MCD Educ</span>
          
          <img  style="border-radius:20px;" width="45px" height="45px" :src="require('./assets/logoMCDEduc.png')"></img>

        </div>
         </div>
         <div class="iconApp" @click="$router.push('/DevisList')" v-if="entreprise!=null">
          <span>Facturation </span>
          <img  style="border-radius:20px" width="45px" height="45px" :src="require('./assets/logobanque.png')"></img>

         </div>
         <div class="d-flex flex-column  align-items-center" >
    
         <div class="iconApp" @click="$router.push('/search')">
          <span>MCD search</span>
          <img  style="border-radius:20px" width="45px" height="45px" :src="require('./assets/MCDSEARCH.png')"></img>

         </div>
       
        
        
         <div class="iconApp" @click="$router.push('/MyCompte')">
          <span>Paramettre</span>
          <img  style="border-radius:20px" width="45px" height="45px" :src="require('./assets/setting.png')"></img>

         </div>
        </div>
         <div class="iconApp" @click=" deconect()">
          <span>Se deconnecté</span>
          <v-icon large style="color:black">mdi-logout-variant</v-icon>

         </div>
    </div>
    <div  v-if="false">
    <div class="DeckDockHoverl animated2 animatedZoomInUp ZoomInUp">
    </div>
  <div class="DeckDock animated2 animatedZoomInUp ZoomInUp">
    <div class="d-flex justify-space-between"> 
      <div class="dock_text d-flex align-items-center" @click="dialog=true" >
       <img
       class="mx-3"
            :alt="`${user.usr_name} avatar`"
            width="50px" height="50px" style="border-radius:70Px"
          :src="$domainName+'/'+ (entreprise? entreprise.ete_img_url:user.usr_img_url)"
          >
         
        <p class=""> {{(entreprise? entreprise.ete_name:(user.usr_name + user.usr_first_name))}}</p>
        
          
     </div>
   

      <div class="mx-6 my-6 d-flex align-center dock_notif">
         
       
        <div class="d-flex align-center mr-2"> 
          
      
          <v-btn dark rounded  @click="deconect()" >
      Se deconnecter  </v-btn>
      </div>
         <!--<v-icon  class="mx-3 btn" style="font-size:40px ;color:black;">mdi-bell</v-icon>-->
         <Notification :notifImportant="notifImportant" :notifNumber="notifNumber" :notifications="notifications"  :drawer="drawer" />
         <v-icon  v-if="checkt()"  class="ARROWDO" @click="opendock=false" 
          style="font-size:20px;position:absolute; top:10px;right:10px;color:grey;">mdi-close</v-icon>
      </div>
      </div>
      <v-text-field style="background: white; padding: 0; height: 55px;" placeholder="Rechercher une Application" color="white" rounded filled class="dock_text_field"></v-text-field>
      <div class="d-flex justify-center align-items_center mt-12 dock_list_app flex-wrap">
   
        <div v-for="e in  listAPP" class="d-flex flex-column align-center flex-wrap" @click="e.ok?listAction(e):''" >
        
        <div class="btn d-flex flex-column align-center "  v-if="e.code==1 | entreprise!=null" :style="e.ok?'':'filter: blur(0.3rem);'">
          <div>
              
        <v-img style="border-radius:15px" class="mx-3 m-3 ICONAPP" :src="e.image" ></v-img>
          </div>
        <h6 style="text-align:center; width:90px" class="textApp">{{e.titre}}</h6>
        </div>
        </div>
          
      </div>


</div>
  </div>
  
 <div >
    <v-icon  @click="opendock?opendock=false:opendock=true" :dark="dark">mdi-apps-box</v-icon>
 </div>
   <v-dialog
      v-model="dialog"
    
      max-width="700"
      max-height="450"
    >
      <template v-slot:activator="{ on, attrs }">
      
      </template>
      <v-card style="border-radius:10px;background:rgba(255,255,255,0.95)">
      <!-- <v-card-title class="headline">
         Changer de compte
        </v-card-title>
        <v-card-text>

             <v-list subheader style="background:rgba(255,255,255,0)">
      <v-subheader>Compte Personnel</v-subheader>

      <v-list-item
      
      >
        <v-list-item-avatar>
          <v-img
            :alt="`${user.usr_name} avatar`"
            @click="selectUser()"
            
            :src="$domainName+'/'+user.usr_img_url"
          ></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title style="color:black" @click="selectUser()" v-text="user.usr_first_name + ' ' + user.usr_name "></v-list-item-title>
        </v-list-item-content>

        <v-list-item-icon>
          
        </v-list-item-icon>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <v-list subheader style="background:rgba(255,255,255,0)">
      <v-subheader>Compte d'entreprise</v-subheader>

      <v-list-item
        v-for="(compte) in entreprises"
        :key="compte.ete_name"
      >
        <v-list-item-avatar>
          <v-img
          @click="SelectEntreprise(compte)"
            :alt="`${compte.ete_name} avatar`"
            :src="$domainName+'/'+compte.ete_img_url"
          ></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title style="color:black"  @click="SelectEntreprise(compte)" v-text="compte.ete_name"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-1"
            text
            @click="dialog = false"
          >
           Fermer
          </v-btn>
        
        </v-card-actions>
      -->
      <div class="container">
    <div class="user-selection">
      <h2>Choisissez un Utilisateur :</h2>
      <div class="d-flex flex-wrap justify-content-center">
      <div class="user-list">
        <div class="user-profile personal-profile">
          <span class="user-initial">{{ user.usr_first_name.charAt(0) }}</span>
        </div>
        <p>{{ user.usr_first_name + ' ' + user.usr_name }}</p>
        <p class="user-type">Compte Privé</p>
        <button class="choose-button" @click="selectUser()">Choisir</button>
      </div>
      
      <div class="user-list" v-for="(compte) in entreprises">
        <div class="user-profile business-profile" 
        :key="compte.ete_name" >
        
          <span class="user-initial">{{compte.ete_name.charAt(0)}}</span>
        </div>
        <p>{{ compte.ete_name }}</p>
        <p class="user-type">Compte Entreprise</p>
        <button class="choose-button"  @click="SelectEntreprise(compte)">Choisir</button>
      </div>
      <div class="user-list">
        <div class="user-profile create-profile">
          <span class="user-initial">C</span>
        </div>
        <p>Créer un Compte</p>
        <p class="user-type">Entreprise</p>
        <button class="choose-button" @click="$router.push('formEntreprise')">Commencer</button>
      </div>
    </div>
    </div>
  </div>
      </v-card>
    </v-dialog>
   <!-- <adsForm></adsForm>-->
    <GestAppPart :dialog="gestapp" @close="gestapp=false"></GestAppPart>
  </div>
</template>
<script>
import {getPlatforms} from '@ionic/vue'
 import ConnectBackend from './views/ConnectBackend'
 import  EventBus  from './views/event-bus';
import Imagerezize from './views/Imagerezize.vue';
import TextEditor6 from './views/TextEditor6.vue';

import Notification from './components/Notification.vue'
import GestAppPart from './components/GestAppPart.vue'
import adsForm from './views/ads_form.vue'
export default {
  components: { TextEditor6, Imagerezize, Notification,GestAppPart,adsForm},
props:["dark"],
  data: ()=>({
      opendock:true,
     AU_2_MCD_42:localStorage.AU_2_MCD_42,
         user:"",
         mobile:false,
        dialog:false,
        isdesktop:true,
        aSPu:"ASUP",
        gestapp:false,
        entreprise:"",
            entreprises: [

      ],
      listAPP:[
        {titre:"E-shop" ,image:require('./assets/MCDmarkette.png'),url:"Home" ,code:1,ok:true},
         {titre:"Produits" ,image:require('./assets/MCDSTOCK.png'), url:"ProductGest",code:0,ok:true},
          {titre:"Facturation" ,image:require('./assets/MCDCompta.png'), url:"DevisList",code:0,ok:true},
         {titre:"Newsletter" ,image:require('./assets/MCDNews.png'), url:"Newsletter",code:0,ok:true},

          {titre:"Site Web" ,image:require('./assets/MCDCRaTION.png') , url:"myWebSite",code:0,ok:true},
          {titre:"Mail" ,image:require('./assets/MCDMAIL.png') , url:"myMail",code:0,ok:true},
          {titre:"MCD Ads" ,image:require('./assets/logopub.png'), url:"pubads",code:0,ok:true},
           {titre:"Social" ,image:require('./assets/MCDjob.png') , url:"WallUser",code:1,ok:true},
             {titre:"Dating" ,image:require('./assets/MCDDating.png') , url:"Dating",code:1,ok:true},
           {titre:"App renvendeur" ,image:require('./assets/MCDGest.png') , url:"MCDGest",code:1,ok:true},
            {titre:"Mes abonements" ,image:require('./assets/MCDSubscription.png') , url:"Dating",code:1,ok:true},
           {titre:"Mes données" ,image:require('./assets/MCDsetting.png'), url:"MyCompte",code:1,ok:true},
            {titre:"Rechercher" ,image:require('./assets/MCDSEARCH.png'),code:1,ok:false},
      ],
      // notifs
      drawer:null,
      notifNumber: 0,
      notifImportant:false,
      notifications:[],
      /////notifs
  }),
   mounted()
  {
    if(getPlatforms().includes("capacitor") || getPlatforms().includes("android") )
 {
  
 this.isdesktop = false
 }
 else{
  this.isdesktop = true
 }
    this.aSPu = "<TitreCSS></TitreCSS>"
        var self =this
        if(window.innerHeight<900)
          {
            self.mobile = true
          }
          else{
            self.mobile = false
          }
        addEventListener("resize", (event) => {
          
          if(window.innerHeight<900)
          {
            self.mobile = true
          }
          else{
            self.mobile = false
          }
        });
      ConnectBackend.getAuth(localStorage.AU_2_MCD_42,'', '/api/v1/user/current' ,self ,function(ret)
      {
      
        self.user = ret.data
       
      } )
       ConnectBackend.getAuth(localStorage.AU_2_MCD_42,'','/backend/v1/getEntrerpiseUser',self  ,function(ret)
      {
        self.entreprises = ret.data
      } )
    var self = this 
    if(localStorage.entreprise!=null)
     this.entreprise = JSON.parse(localStorage.entreprise)
    else
      this.entreprise =  null
    EventBus.$on('SyncAU2',function(result){
    self.AU_2_MCD_42=localStorage.AU_2_MCD_42   
      ConnectBackend.getAuth(localStorage.AU_2_MCD_42,'', '/api/v1//user/current' ,self  ,function(ret)
      {
       
        self.user = ret.data
      } )
       ConnectBackend.getAuth(localStorage.AU_2_MCD_42,'','/backend/v1/getEntrerpiseUser',self  ,function(ret)
      {
        self.entreprises = ret.data
      } )   
    });
    self.getNotifs() 
    if(window.location.hostname == "follow-me.dating")
    {
      this.opendock  = false
    }
  },
  watch:{
    $route (to, from){
        if(window.location.pathname=='/')
        {
          this.opendock = true
        }
        else{
          false
        }
        this.checkt()
    }
  },
  methods:
  {
    opencloud()
    {
      var t = btoa(this.user.user_token_cloud)
      location.href =  'https://alphacloud.mcd-global-system.be/index.php/login?user='+this.user.email+'&code='+t
    },

    mcdTalk()
    {
      window.location.href ='https://mcdapp.m-code.net/mcdtalk'
    },
      checkt()
       {
           return true
       },
       listAction(item)
      {
        this.opendock=false
        if(item.url=="MCDGest")
        {
          this.gestapp =true
        }
        else{
              item.title=='Changer de comptes'?this.dialog=true:this.$router.push('/'+item.url)
        }
    
        
      },
        
    deconect(){
      
       localStorage.removeItem('AU_2_MCD_42');
       localStorage.removeItem('entreprise');
       this.entreprise = null;
           EventBus.$emit('SyncAU2', true);
           this.$router.push('/login')
      
    },
       SelectEntreprise(entreprise)
      {
        this.entreprise =entreprise
        localStorage.entreprise =JSON.stringify(entreprise)
        this.listAPP = JSON.parse(JSON.stringify(this.listAPP))
        this.dialog =false
        document.location.reload();
      }
      ,selectUser()
      {
         this.entreprise =null
        localStorage.entreprise =null
         this.listAPP = JSON.parse(JSON.stringify(this.listAPP))
        this.dialog =false
        document.location.reload();
      }
      ,
      getNotifs(){
        var self =this

        ConnectBackend.getAuth(localStorage.AU_2_MCD_42,'','/backend/v1/notifications/show-unseen-user-notifications' , this ,function(ret){


            self.notifications = ret.data


              self.notifications.forEach(notification => {

                if(notification.noti_seen ==false)
                {
                  self.notifNumber += 1
                  if(notification.noti_important ==true)
                  {
                  self.notifImportant =true
                  }
                }
            })


        },function(){
          self.error=true
        })    
    }
  }
}
</script>
<style scoped>
.mxSmarthopen img,.mxSmarthopen .v-icon{
  width: 15px!important;
  height: 15px!important;
  font-size: 7px!important;
 margin: 0px!important;
 padding: 0px!important;
  display: flex;
  flex-wrap: wrap;
}
.mxSmarthopen{
  display: flex;

  position: relative;
  flex-wrap: wrap;
  margin: 5px 10px;
  width: 49px;
  min-height: 49px;
  padding: 5px;
  background: rgba(0, 0, 0, 1);
  border-radius: 20px;
}
.mxSmarthopen:hover {
  flex-direction: column;
}
.mxSmarthopen:hover  .iconApp{
  padding: 5px  5px!important;
  margin: 10px 10px 10px -5px!important;
  width: 50px;
  height: 50px;
}
.iconApp img{
  box-shadow: 0 0 2px lightgray;
}
.mxSmarthopen:hover img, .mxSmarthopen:hover .v-icon {
  font-size: 15px!important;
  width: 40px!important;
  height: 40px!important;
}
.mxSmarthopen div{
  padding: 0px!important;
}
.mxSmarthopen .iconApp{
  margin: 0px!important;
  width: 19px;
  height: 20px;
  padding: 2px!important;
}
.iconApp{
  display: flex;

  align-items: center;
  justify-content: center;
  position: relative;
  left: 0;
  padding: 5px  10px;
  margin: 5px 0;
  color: white;
  background: none;
  transition: all 1s;
}
.iconApp span {
  white-space: nowrap;
  opacity: 0;
  width: 0;
 
  transition: all 1s;
  overflow: hidden;
}
.iconApp:hover{
  display: flex;
  width: 180px!important;
  align-items: center;
  justify-content: center;
  position: relative;
  left: -70px;
  background: black;
  color: white;
  border-radius: 30px;
}
.mxSmarthopen  .iconApp:hover {
  left: -130px;
}
.iconApp:hover span{
  width: 130px;
  
  opacity: 1;

}
.v-icon{
  transition: all 1s;
}
.iconApp .v-icon{
 

}
.DockNewOpen{

  z-index: 99999;
  width: 70px;

  
  height: 100vh;
   
  background: rgba(255, 255, 255, 0.99);

  border-radius: 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dock_list_app{
  margin: 50px 20%!important;
  padding-top: 70px ;
}
.dock_text_field{
  width: 500px;
  position: fixed;
  top: 15px;
  right:  0px;
  left: 0;
  margin: 0 auto!important;
}
.dock_notif{
  position: fixed;
  top: 0px;
  right:  20px;
}
.dock_text p {
  font-size: 20px;
  font-weight: bold;
}
.dock_text{
  position: fixed;
  top: 15px;
  left: 0px;
  color: white;
}
.Dock_color_white{
  color: white!important;;
}
.textEntreprsise{
  padding-top: 15px;
  margin: 0;
  font-weight: 500;
  font-size: 20px;
}
.ARROW:hover{

  font-size: 65px!important;
    
}
.ARROWDO{
  color: white!important;
  right: -30px!important;
  position: absolute;
}
.ARROWDO:hover{

  font-size: 35px!important;
    
}
.ICONAPP{
  width:100px;
  height:100px;
}
  .DeckDock{
  width: 100%;
  height: 100%;
 
   position: fixed;
   margin:  auto;
   left: 0;
   right: 0;
   top: 0;
   bottom: 0;
  
  z-index: 8;
  border-radius: 10px;

 overflow-x: hidden;
 overflow-y: scroll;
}
.DeckDockHoverl
{
   background: rgba(0, 0, 0, 0.70);
   backdrop-filter: blur(15px);
   width: 100%;
   height: 100%;
   margin: auto;
   left: 0;
   display: flex;
 
   top:0;
   position: fixed;
   z-index: 7;
}
.dockup{
  position:fixed;
  width: 100Px;
  height: 50px;
  bottom: 0;
  z-index: 9;
  margin: auto;
  left:0;
  right: 0;
  background: white;
   box-shadow: 0px 0px 4px gray;
  display: flex;
  justify-content: center;
  border-radius: 10px 10px 0px 0;



}
.ICONAPP:hover{

    width: 107px!important;
    height: 107px!important;
    
}
.textApp{
   color: white;
  }
@media screen and (max-width: 1000px)
{
  .dock_text_field{
    margin: 10px;
    left: 10px;
    right: 10px;
    top: 100px;
    width: 300px;
  }
  .dock_text{
    top:10px;
    left: 10px;
    position: fixed;
    z-index: 1;
  }
  .dock_text p{
    display: none;
  }
  .dock_list_app{
  margin: 0px 0%!important;
  padding-top: 70px ;
}
  .textApp{
    display: none;
  }
   .DeckDock{
  width: 100%;
  height: 100%;
  

  z-index: 999;
  border-radius: 40px;

}
.DeckDockHoverl
{
  
   width: 100%;
   height: 100%;
   margin: auto;
   left: 0;
   display: flex;
   justify-content: center;
   align-items: center;
   top:0;
   position: fixed;
   z-index: 99;
}
.dockup{
  position:fixed;
  width: 100Px;
  height: 50px;
  bottom: 0;
  z-index: 9000;
  margin: auto;
  left:0;
  right: 0;
 
  display: flex;
  justify-content: center;
  border-radius: 10px 10px 0px 0;



}
.ICONAPP:hover{

    width: 77px!important;
    height: 77px!important;
    
}
.ICONAPP{
  width:70px;
  height:70px;
}
.MCJLO{
  display: none;
}
.seDeco{
  
}
}
@keyframes fadeInUp {
    from {
      opacity: 0
        
    }

    to {
       
        
        opacity: 1
    }
    
}

@-webkit-keyframes fadeInUp {
    from {
        transform: translate3d(0,240px,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

.animated{
    animation-duration: 0.5s;
    animation-fill-mode: both;
   animation-duration: 0.5s;
    -webkit-animation-fill-mode: both
}
.animated2 {
    animation-duration: 1s;
    animation-fill-mode: both;
   animation-duration: 1s;
    -webkit-animation-fill-mode: both
}
.animatedFadeInUp {
    opacity: 0
}

.fadeInUp {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
}
@keyframes ZoomInUp {
  0% {
        display: none;
        opacity: 0;
        backdrop-filter: blur(0);
    }

    1% {
        display: block;
        opacity: 0;
        backdrop-filter: blur(15px);
    }

    100% {
        display: block;
        opacity: 1;
        backdrop-filter: blur(15px);
    } 
    
    
}

@-webkit-keyframes ZoomInUp {
  0% {
        display: none;
        opacity: 0;
        backdrop-filter: blur(0px);
    }

    1% {
        display: block;
        opacity: 0;
        backdrop-filter: blur(0);
    }

    100% {
        display: block;
        opacity: 1;
        backdrop-filter: blur(15px);
    } 
    
}


.animatedZoomInUp{
    opacity: 0
}

.ZoomInUp {
    opacity: 0;
    animation-name: ZoomInUp;
    -webkit-animation-name: ZoomInUp;
}
/* styles.css */
body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;

 

}

.user-selection {
  text-align: center;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 30px;
 
}

.user-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 150px;
  justify-content: center;
  margin: 20px;
}

.user-profile {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  font-size: 32px;
  color: #ffffff;
  margin-bottom: 10px;
}

.personal-profile {
  background-color: #007BFF;
}
.create-profile {
  background-color: #333;
}

.business-profile {
  background-color: #34C759;
}

p {
  font-size: 18px;
  margin: 0;
}


.user-list {
  
  margin: 20px;
  text-align: center;
}

.user-type {
  font-size: 14px;
  color: #888888;
  margin: 0;
}

.choose-button {
  padding: 12px 24px;
  background-color: #000000;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.choose-button:hover {
  background-color: #1c1c1e;
}

.choose-button:focus {
  outline: none;
}

</style>