const a = require('axios');
const appkey = "E84138131F5F8FD9CB5B1A6316784"
const url = "https://mcd.m-code.net"
//const url = "https://alphaback.mcd-global-system.be/"


const axios = a.create({

});
exports.post = function(element, chemin,self, callback,  callerror) {
    axios.post(url + chemin,{ element },

        {
            headers: {  'SASKEY' : appkey   }
        })
        .then(function(response) {
           
            callback(response)
        })
        .catch(function(error) {
           
            callerror(error)
        });
};
exports.postAuth = function(token, element, chemin,self, callback, callerror) {
    axios.post(
            url + chemin,

            { element },

            {
                headers: {  'Authorization': token    } 
            })
        .then(function(response) {
            console.log(response);
            callback(response)
        })
        .catch(function(error) {
            
          if(error.response.status == 406)
          {
            window.location.href = "/page406Error";
           }
            //callerror(error)
        });
};
exports.getAuth = function(token, element, chemin, self, callback, callerror,cook) {
    
    axios.get(
            url + chemin, {
                params: {
                    element
                },

                headers: {  'Authorization': token    } 

            
            }
            ,

)
        .then(function(response) {
            console.log(response);
            callback(response)
        })
        .catch(function(error) {
            
            if(error.response.status == 406)
            {
              //window.location.href = "/page406Error";
             }
           // console.log(error);
            callerror(error)
        });
};
exports.putAuth = function(token, element, chemin,self, callback, callerror) {
    axios.put(
            url + chemin, { element },

            {
                headers: {  'Authorization': token    } 
            }



            ,

        )
        .then(function(response) {
            console.log(response);
            callback(response)
        })
        .catch(function(error) {
            if(error.response.status == 406)
            {
              window.location.href = "/page406Error";
             }
            console.log(error);
            //callerror()
        });
};
exports.pathAuth = function(token, element, chemin, self, callback, callerror) {
    axios.patch(
            url + chemin,
            { element },

            {
                headers: {  'Authorization': token    } 
            }



            ,

        )
        .then(function(response) {
            console.log(response);
            callback(response)
        })
        .catch(function(error) {
            if(error.response.status == 406)
            {
              window.location.href = "/page406Error";
             }
            console.log(error);
            //callerror()
        });
};
exports.deleteAuth = function(token, element, chemin, self,callback, callerror) {
    axios.delete(
            url + chemin, {
                params: {
                    element
                },

                headers: {  'Authorization': token    } 

            }

            ,

        )
        .then(function(response) {
            console.log(response);
            callback(response)
        })
        .catch(function(error) {
            if(error.response.status == 406)
            {
              window.location.href = "/page406Error";
             }
            console.log(error);
            //callerror()
        });
};
function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }