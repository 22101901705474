<template>
 <!-- <div class="about">
  <v-dialog v-model="dialog"   persistent max-width="700">
  
      <v-form
    ref="form2"
    v-model="valid"
    lazy-validation
  >
            <v-card
        
         elevation="0"
         style="padding:30px"
         
        >
        <h3>Information de l'entreprise</h3>
        <div :class="'d-flex '+ (mobile? 'flex-column':'')">
        <v-text-field  :rules="defautRules"  solo v-model="infoEntreprise.ete_name" placeholder="Nom" :class="mobile?'':'mr-3'"></v-text-field>
        <v-text-field :rules="defautRules"  solo v-model="infoEntreprise.ete_ntva" placeholder="Numero TVA" :class="mobile?'':'ml-3'"></v-text-field>
        <v-text-field :rules="defautRules"  solo v-model="infoEntreprise.ete_ent_number " placeholder="Numero TVA" ></v-text-field>
      
        </div>
        <div :class="'d-flex '+ (mobile? 'flex-column':'')">
        <v-text-field :rules="defautRules"  solo v-model="infoEntreprise.email" placeholder="Email" :class="mobile?'':'mr-3'"></v-text-field>
        <v-text-field :rules="defautRules"  solo v-model="infoEntreprise.phone_number" placeholder="Téléphone" ></v-text-field>
        </div>
          <div :class="'d-flex '+ (mobile? 'flex-column':'')">
              <v-text-field solo  :rules="defautRules"  v-model="infoEntreprise.ete_description" placeholder="Descritption"></v-text-field>
          <v-text-field solo :rules="defautRules"  placeholder="IBAN" ></v-text-field>
        <v-text-field solo :rules="defautRules"  placeholder="BIC" ></v-text-field>
         <v-file-input
    label="Photo de profil"
    class="mx-4 mt-1"
    dense
    :rules="defautRules" 
    v-model="imageEntreprise"
    solo
  ></v-file-input>
   <v-file-input
    label="Cover"
    class="mx-4 mt-1"
    v-model="entrepriseCover"
    :rules="imageRules"
    dense
    
    solo
  ></v-file-input>
     </div>
      <div :class="'d-flex '+ (mobile? 'flex-column':'')">
        <v-text-field :rules="defautRules"  solo placeholder="Rue" v-model="adresses.ads_street"></v-text-field>
        
        <v-text-field :rules="defautRules"  solo placeholder="Code postal" v-model="adresses.ads_postal_code"></v-text-field>
        <v-text-field :rules="defautRules"  solo placeholder="Ville" v-model="adresses.ads_city"></v-text-field>
        </div>
        <v-text-field :rules="defautRules"  solo placeholder="Etat" v-model="adresses.ads_state"></v-text-field>
         <v-text-field :rules="defautRules"  solo placeholder="Pays" v-model="adresses.ads_country"></v-text-field>
         <div class="d-flex" style="flex-direction: column;">
      <v-btn color="success">Sauvegarder l'entreprise</v-btn>
    <v-btn class="mt-4 " @click="$emit('close')">Annuler</v-btn>
    </div>
        </v-card>
  </v-form>
  
  </v-dialog>
  </div>-->
</template>
<script>
export default {
    props:['dialog'],
  data: ()=>({
    adresses:{},
   infoEntreprise:{},
     defautRules:[
           v => !!v || 'Champs is required'
       ]
  }),created(){
      if(!localStorage.AU_2_MCD_42)
        this.$router.push('/login')
    },
}
</script>