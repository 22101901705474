<template>
<!--
  <v-app style="background:#f2f2f2">
    <v-app-bar
    
      color="white"
     fixed
      style="border-radius: 0 0 50px 50px;z-index:100;"
    >
   
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://mcd-global-system.com/wp-content/uploads/2020/10/122668929_691989981700965_3974363832971572503_n.png"
          transition="scale-transition"
          width="200"
        />

      
      </div>

      <v-spacer></v-spacer>
         
      <div   style="width:360px!important" class="mr-4">
        
 <v-text-field
            label="Rechercher"
            class="mt-7 "
            rounded
            v-model="search"
            @change="SearchList()"
            solo
            append-icon="mdi-magnify"
          ></v-text-field>
          </div>
       <div class="mx-6 d-flex">
             <p class="mt-4 mr-3">{{Total}}€</p>
            <v-icon  v-if="AU_2_MCD_42">mdi-cart</v-icon>
          
          </div>
      <v-btn
        v-if="!AU_2_MCD_42"
        target="_blank"
        class="mr-3"
        @click="$router.push('/login')"
        dark
        color="primary"
        rounded
      >
        Connection
      </v-btn>
           <v-btn
        v-if="AU_2_MCD_42"
        target="_blank"
        class="mr-3"
        @click="deconect()"
        dark
        color="primary"
        rounded
      >
        déconnection
      </v-btn>
       <v-btn
          v-if="!AU_2_MCD_42"
        target="_blank"
        dark
        color="orange"
         class="mr-3"
         @click="$router.push('/inscription')"
        rounded
      >
       Inscription
      </v-btn>
     
    </v-app-bar>
<navigation-draw app></navigation-draw>
    <v-main >
      <div class="mt-12"></div>
      <router-view></router-view>
    </v-main>
    <v-footer padless>
    <v-col
      class="text-center"
      cols="12"
    >
      {{ new Date().getFullYear() }} — <strong>MCD Global System</strong>
    </v-col>
  </v-footer>
  </v-app><Messager></Messager>
  -->
    <v-app style="background:#f2f2f2">
    
  <v-main >

      <router-view></router-view>
   <!--   <Adsense
    data-ad-client="ca-pub-1523500557896744"
     data-ad-slot="6526716062"
     data-ad-format="auto"
     data-full-width-responsive="true"
    >
    </Adsense>-->
    </v-main>
<Cookie></Cookie>
<!--<GestAppPart></GestAppPart>-->
    </v-app>
</template>

<script>
import NavigationDraw from './views/NavigationDraw.vue';
import store from './store/index.js'
import AppBarAPP from './AppBarAPP.vue'
import DockBar from './DockBar.vue'
import  EventBus  from './views/event-bus';
import Cookie from './views/Cookie.vue'
import GestAppPart from './components/GestAppPart.vue'
import Messager from './views/Messager.vue';
import { LocalNotifications } from '@capacitor/local-notifications'
import ConnectBackend from './views/ConnectBackend'
import ActionCable from 'actioncable'
import { PushNotifications } from '@capacitor/push-notifications';
import {getPlatforms} from '@ionic/vue'
import { FCM } from '@capacitor-community/fcm';
export default {
  name: 'App',

  components: {
    NavigationDraw,
    DockBar,
    AppBarAPP,
    Cookie,
    GestAppPart,
    Messager
},
 async mounted(){
 
   
// This will set a cookie with the name 'test' and the value 'Hello world!' that expires in one day



    var self = this 
// Request permission to use push notifications


// external required step
// register for push
PushNotifications.addListener('registration', (data) => {
      // alert(JSON.stringify(data));
      console.log(data);
    });
    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification) => {
     
        console.log('notification ' + JSON.stringify(notification));
        this.zone.run(() => {
          this.notifications.push(notification);
        });
      }
    );
    PushNotifications.requestPermissions().then((response) =>
      PushNotifications.register().then(() => console.log(`registered for push`))
    );

// now you can subscribe to a specific topic


FCM.getToken()
  .then(r => console.log(`Token ${r.token}`))
  .catch(err => console.log(err));

// Enable the auto initialization of the library
FCM.setAutoInit({ enabled: true }).then(() => console.log(`Auto init enabled`));

FCM.isAutoInitEnabled().then(r => {
  console.log('Auto init is ' + (r.enabled ? 'enabled' : 'disabled'));
});

ConnectBackend.getAuth(localStorage.AU_2_MCD_42,'', '/api/v1/user/current',self ,function(ret)
{
  
  if((!ret.data.usr_date || !localStorage.AU_2_MCD_42)  && window.location.pathname!="/login" && window.location.pathname!="/inscription" && window.location.pathname!="/"  && window.location.pathname!="/search"  && window.location.pathname!="/"  && window.location.pathname!="/entBook" )
    {
       self.$router.push('/login')
    }
    ConnectBackend.getAuth(localStorage.AU_2_MCD_42, "", '/backend/v1/DatingProfile/get_user_prof/' + "Dating", self,
          function (ret) 
          {

           var  p = ret.data[0]
            FCM.subscribeTo({ topic: p.id })
  .then(r => console.log(`subscribed to topic` + p.id))
  .catch(err => console.log(err));

          })
  var age = self.getAge(ret.data.usr_date)

    var rule = JSON.parse(localStorage.CookiesPref) 
  
    if(rule.cookMCDPub)
    {
      var ret =""
      if(age <19)
        ret = "13-18"
      else{
        if(age <26)
        ret = "19-25"
      else{
        if(age <36)
        ret = "26-35"
      else{
        if(age <50)
        ret = "36-50"
      else{
        if(age <70)
        ret = "50-70"
      else{
        ret = "71- "
      }
      }
      }
      }
      }
      
      self.$cookie.set('g_mcd_base_42', ret.data.usr_gender, 2);
      self.$cookie.set('y_mcd_base_42', ret, 2);
      
    }
  },function(error){
  
    if( window.location.pathname!="/login" && window.location.pathname!="/inscription" && window.location.pathname!="/"  && window.location.pathname!="/search"  && window.location.pathname!="/"  && window.location.pathname!="/entBook" )
    {
      localStorage.AU_2_MCD_42 = null
       self.$router.push('/login')
    }
  })
  
    EventBus.$on('SyncAU2',function(result){
     
      self.AU_2_MCD_42=localStorage.AU_2_MCD_42      
    });
    if(!localStorage.AU_2_MCD_42 && window.location.pathname!="/login" && window.location.pathname!="/inscription" && window.location.pathname!="/"  && window.location.pathname!="/search"  && window.location.pathname!="/"  && window.location.pathname!="/entBook"  )
    {
        this.$router.push('/login')
    }
      EventBus.$on('rechargebasqueste',function(result){
     
     self.getbascket()   
    });

   
  console.log("oki 35")

    


  },
  methods:
  {
   
    getAge(dateString) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        return age;
      },
    deconect(){
      
       localStorage.removeItem('AU_2_MCD_42');
           EventBus.$emit('SyncAU2', true);
           this.$router.push('/login')
      
    },
     getbascket(){
    
   
    },
    SearchList()
    {
      this.$router.push('/SearchList/'+this.search)
    },
    
  },

  data: () => ({
   AU_2_MCD_42:localStorage.AU_2_MCD_42,
   search:"",
   Total:0,
   profile:{},
   notifications:[],
   connection:"",
   fcm: new FCM(),
   roomConnections:[],
  }),
  computed:{
   
  }
};
</script>
<style >
.v-toolbar{
flex:0!important;
}
</style>