import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user:1,
    panier:{},
    enableUpdate:true,
  },
  mutations: {
    setenableUpdate(state,etat)
    {
      state.enableUpdate = etat
      //localStorage.Basket = JSON.stringify(panier)
    },
    connect(state,u)
    {
     state.user =u
    },
    setpanier(state,panier)
    {
      //localStorage.Basket = JSON.stringify(panier)
    },
    addpanier(state,para)
    {
      if(localStorage.Basket)
      state.panier = JSON.parse(localStorage.Basket)
      if(state.panier[para.index])
      {
      
        state.panier[para.index]={qt: (state.panier[para.index].qt+parseInt(para.qt)),produit:para.produit}
      }
      else{
        state.panier[para.index]={qt:parseInt(para.qt),produit:para.produit}
      
      }
      localStorage.Basket = JSON.stringify(state.panier)
     
    }

  },
  getters:{
    panier(state){
      return JSON.parse(localStorage.Basket)
    },
    getenableUpdate(state){
      return state.enableUpdate
    }

  },
  actions: {
  },
  modules: {
  
  }
})
