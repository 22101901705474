<template>
  <div >
        <div >


     <v-dialog
      v-model="dialog"
      persistent
      max-width="750"
    >
      <template v-slot:activator="{ on, attrs }">
       
      </template>
      <v-card>
        <v-card-title class="text-h5">
       Redimentionner l'image
        </v-card-title>
        <v-card-text>


        <cropper
  
    :style="icon?'height: 300px;':''"
    ref="cropper"
    :src="img"
  default-boundaries="fill"
	priority="visibleArea"
    :default-size="cropSize"
    :stencil-props="aspectRatio"
  
    
  ></cropper>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="$emit('close')"
          >
            Annuler
          </v-btn>
          <v-btn
            color="green darken-1"
            text
         @click="change"
          >
            Redimentionner
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
 
  </div>
    </div >
</template>
<script>
// @ is an alias to /src
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import uploadsFile from '../mixin/uploadFile'
export default {

mixins:[uploadsFile],
props:['img','dialog','icon'],
   data(){
    return{
         
       image:'',
     
       img2:{"src":"https://images.pexels.com/photos/226746/pexels-photo-226746.jpeg","width":3698,"height":5547,"transforms":{"rotate":0,"flip":{"horizontal":false,"vertical":false},"translateX":0,"translateY":0,"scaleX":0.08112493239588968,"scaleY":0.08112493239588968}}
    }
    },
    mounted(){
      
     
    
    },
    computed:
    {
      	 aspectRatio(){
          if(this.icon)
            return {	resizable: false,}
          else
            return {	resizable: true} 
        },
        cropSize(){
           if(this.icon)
          return {	width: 250,
		height: 250}
    else
            return {width: 1000,
		height: 1000	} 
        }
    },
  methods: {
    async change({coordinates, canvas2}) {

  const result = this.$refs.cropper.getResult();

      
      this.image = result.canvas.toDataURL(
        "image/jpeg"
      )
      var url= await  this.postImage(result.canvas.toDataURL(
        "image/jpeg"
      ),'website')
      this.$emit('changed',url)
      

    }
  },
  components: {
    Cropper
  }

}
</script>
<style >
.cropper {
  height: 500px;
  background: #DDD;
}
</style>