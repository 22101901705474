import Vue from 'vue'
import VueRouter from 'vue-router'

const Home = () =>
    import ('../views/Home.vue')
    const cloud = () =>
    import ('../views/cloud.vue')
    const SandBoxWeb = () =>
    import ('../views/SandBoxWeb.vue')
    const mobilemode = () =>
    import ('../views/mobilemode.vue')
const Login = () =>
    import ('../views/Login.vue')
const Inscription = () =>
    import ('../views/Inscription.vue')
const WallUser = () =>
    import ('../views/WallUser')
const myWebSite = () =>
    import ('../views/MyWebSite')
    const myMail = () =>
    import ('../views/MyMail')
const myCarte = () =>
    import ('../views/MyCarte')
const SearchList = () =>
    import ('../views/SeachList')
const MyCompte = () =>
    import ('../views/MyCompte')
const WallPublic = () =>
    import ('../views/WallPublic')
const ProductGest = () =>
    import ('../views/productgest')
const Shop = () =>
    import ('../views/shop')
const DevisList = () =>
    import ('../views/DevisList')
const GestCustomer = () =>
    import ('../views/GestCustomer')
const EditeWebSite = () =>
    import ('../views/EditeWebSite')

const SubTest = () =>
    import ('../views/zTempSubsTests')
const PaymentAccept = () =>
    import ('../views/PaymentAccept')
const OrderList = () =>
    import ('../views/OrderList.vue')
const BillList = () =>
    import ('../views/BillList.vue')
const CustomerList = () =>
    import ('../views/CustomerList.vue')
const ComptePayement = () =>
    import ('../views/ComptePayement.vue')
const TypeCutome = () =>
    import ('../views/TypeCutome')
    const TypeCutomMaster = () =>
    import ('../views/TypeCutomMaster')
const BasketMCD = () =>
    import ('../views/BasketMCD')
const HomEshop = () =>
    import ('../views/HomEShop')
const PointsOfsale  = () =>
  import ('../views/PointsOfsale')
const Billspage  = () =>
  import ('../views/BillsPage')
const NewsLetter  = () =>
  import ('../views/NewsletterEditor')  
const Dating = () =>
  import ('../views/Dating')  
  
const DeleveryList =() =>
    import ('../views/DeleveryList')
const ConnectionCustomer =() =>
    import ('../views/ConnectionCustomer')
const CustomersTypes =() =>
    import ('../views/CustomersTypes')
const CreatorCarte =() =>
    import ('../views/CreatorCarte/CreatorCarte')
    const McdChatVideo =() =>
    import ('../views/McdChatVideo')
    const choixuser =() =>
    import ('../views/choixuser')
const Talk =() =>
    import ('../views/Talk')
    const Educ =() =>
    import ('../views/educ')
    const search =() =>
    import ('../views/search')
    const studentapp =() =>
    import ('../views/StudentApp')
    const HoraireEduc =() =>
    import ('../views/HoraireEduc')
    const mcdSchool=() =>
    import ('../views/mcdSchool')
    const pubAds=() => 
    import ('../views/pubAds')
    const entBook=() => 
    import ('../views/entBook')
    const searchresul=() => 
    import ('../views/searchresul')
    
    const FormEntreprise=() => 
    import ('../views/FormEntreprise')
    const BillPurchases=() => 
    import ('../views/BillPurchases')
    const ProductCompo=() => 
    import ('../views/ProductCompo')
Vue.use(VueRouter)

const routes = [{
    path: '/pubAds',
    name: 'pubAds',
    component: pubAds
    
},{
        path: '/ProductCompo',
        name: 'ProductCompo',
        component: ProductCompo
        
    },
    {
        path: '/',
        name: 'search',
        component: search
        
    },
   
    {
        path: '/BillPurchases',
        name: ' BillPurchases',
        component: BillPurchases
        
    },
    {
        path: '/searchresul',
        name: 'searchresul',
        component:    searchresul
        
    },
    {
        path: '/entBook',
        name: 'entBook',
        component:  entBook
        
    },
   
    
    {
        path: '/choixuser',
        name: 'choixuser',
        component: choixuser
        
    },

    {
        path: '/FormEntreprise',
        name: 'FormEntreprise',
        component:  FormEntreprise
        
    },
   
    {
        path: '/mcdSchool',
        name: 'mcdSchool',
        component: mcdSchool
        
    },
    {
        path: '/HoraireEduc',
        name: 'HoraireEduc',
        component: HoraireEduc
        
    },
    {
        path: '/search',
        name: 'search',
        component: search
        
    },
    {
        path: '/studentapp',
        name: 'studentapp',
        component: studentapp
        
    },
    {
        path: '/educ',
        name: 'educ',
        component: Educ
        
    },
    {
    path: '/mcdtalk',
    name: 'mcdtalkTalk',
    component: Talk
    
},
{
    path: '/cloud',
    name: 'cloud',
    component: cloud
    
},

    {
    path: '/index.html',
    name: 'index',
    component: Login
    
},
    {

        path: '/Dating',
        name: 'Dating',
        component: Dating
        
    },
    {

        path: '/SocEduc',
        name: 'SocEduc',
        component: Dating
        
    },
    {
        path: '/SweetMatching',
        name: 'SweetMatching',
        component: Dating
       
    },
    {
        path: '/SweetDating',
        name: 'SweetDating',
        component: Dating
       
    },
    {
        path: '/SocialWork',
        name: 'SocialWork',
        component: Dating
        
    },
    {
        path: '/Atipo',
        name: '/Atipo',
        component: Dating
        
    },
    {
        path: '/CreatorCarte',
        name: 'CreatorCarte',
        component: CreatorCarte
        
    },
    {
        path: '/NewsLetter',
        name: 'NewsLetter',
        component: NewsLetter
        
    },
    {
        path: '/PointsOfsale',
        name: 'PointsOfsale',
        component: PointsOfsale
        
    },
    {
        path: '/Erp-workflow/:id',
        name: 'Erp-workflow',
        component: Billspage 
        
    }
   
    
    , 
    {
        path: '/SandBoxWeb/:id/:page',
        name: 'SandBoxWeb',
        component:  SandBoxWeb
        
    },
    {
        path: '/mobilemode/:id/:type/:page',
        name: 'mobilemode',
        component:  mobilemode
        
    },{
        path: '/Home',
        name: 'Home',
        component: HomEshop
        
    },
    
    {
        path: '/BasketMCD',
        name: 'BasketMCD',
        component: BasketMCD
        
    },

    {
        path: '/TypeCutome',
        name: 'TypeCutome',
        component: TypeCutome
    },
    {
        path: '/TypeCutomMaster',
        name: 'TypeCutomMaster',
        component: TypeCutomMaster
    },
    {
        path: '/CustomerList',
        name: 'CustomerList',
        component: CustomerList
    },
    {
        path: '/ComptePayement',
        name: 'ComptePayement',
        component: ComptePayement
    },
    {
        path: '/ProductGest',
        name: 'ProductGest',
        component: ProductGest
    },
    {
        path: '/EditeWebSite/:website',
        name: 'EditeWebSite',
        component: EditeWebSite
    },
    {
        path: '/McdChatVideo/:room/:user',
        name: 'McdChatVideo',
        component: McdChatVideo
    },
    {
        path: '/OrderList',
        name: 'OrderList',
        component: OrderList
    },
    {
        path: '/BillList',
        name: 'BillList',
        component: BillList
    },
    {
        path: '/GestCustomer',
        name: 'GestCustomer',
        component: GestCustomer
    },




    {
        path: '/DevisList',
        name: 'DevisList',
        component: DevisList
    },
    {
        path: '/Shop',
        name: 'Shop',
        component: Shop
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/WallPublic/:type/:id',
        name: 'WallPublic',
        component: WallPublic
    },
    {
        path: '/SearchList/:search',
        name: 'SearchList',
        component: SearchList
    },
    {
        path: '/MyCompte',
        name: 'MyCompte',
        component: MyCompte
    },

    {
        path: '/myCarte',
        name: 'myCarte',
        component: myCarte
    },
    {
        path: '/myWebSite',
        name: 'myWebSite',
        component: myWebSite
    },
    {
        path: '/myMail',
        name: 'myMail',
        component: myMail
    },
    {
        path: '/WallUser',
        name: 'WallUser',
        component: WallUser
    },
    {
        path: '/inscription',
        name: 'Inscription',
        component: Inscription
    },

    {
        path: '/subTest',
        name: 'SubTest',
        component: SubTest
    },
    {
        path: '/PaymentAccept',
        name: 'PaymentAccept',
        component: PaymentAccept
    },
    {
        path: '/DeleveryList',
        name: 'DeleveryList',
        component: DeleveryList
    },
    {
        path: '/CustomersTypes',
        name: 'CustomersTypes',
        component: CustomersTypes
    },

{
    path: '/ConnectionCustomer',
    name: 'ConnectionCustomer',
    component: ConnectionCustomer
},
{
    path: '/add-ad/:ads_type',
    name: 'AddAd',
    component: () => import('../views/ads/ads_form_add.vue'),
    props: true
  },
  {
    path: '/ads-info/:ads_type/:ads_id',
    name: 'AdsInfo',
    component: () => import('../views/ads/ads_info.vue'),
    props: true
  },
  {
    path: '/ads-main/:ads_type',
    name: 'AdsMain',
    component: () => import('../views/ads/ads_main.vue'),
    props: true
  },
  {
    path: '/my-ads/:ads_type',
    name: 'MyAds',
    component: () => import('../views/ads/ads_my_ad.vue'),
    props: true
  }
  ,
  {
    path: '/Ad_update/:ads_type/:ads',
    name: 'Ad_update',
    component: () => import('../views/ads/ads_form_modif.vue'),
    props: true
  }
  ,
  {
    path: '/Ads_messager/:ads_type',
    name: 'ads_messager',
    component: () => import('../views/ads/ads_messager.vue'),
    props: true
  }
  ,
  {
    path: '/verif_ads',
    name: 'verfi_main',
    component: () => import('../views/ads/verificateur/verfi_main.vue'),
    props: true
  }
  ,
  {
    path: '/stat_web/:web_id',
    name: 'stat_web',
    component: () => import('../views/Website/web_stat.vue'),
    props: true
  }
  ,
  {
    path: '/erp_stat',
    name: 'erp_stat',
    component: () => import('../views/ERP_stat.vue'),
    props: true
  }
,{
    path: '*',
    name: 'catchALL',
    component: Login
    
},

]

const router = new VueRouter({
    mode: 'history',
    
    base: process.env.BASE_URL,
    routes
})

export default router