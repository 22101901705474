<template>
  <div class="about">
    <div class="mt-5 d-flex shadow textInputMenu align-items-center" @click=" openFile()"> 
   <img :src="url" :style="'width:35px;height:35px;object-fit:cover;border-radius:100px'"/>
   <label class="ml-2">Modifier l'image </label>
  </div>
    <div class="d-flex ">
    
   


          <v-file-input 
         :id="'fileUpload'+idval"
   @change="Preview_image()"
   v-model="image"
    prepend-icon="mdi-camera"
 style="display:none"
   show-size
    
    solo

   placeholder="selectionner une image"
   >
   
</v-file-input>
<imagerezize :icon="icon" @changed="changed" :dialog="dialog" @close="dialog=false" :img="img"></imagerezize>

  </div>
     <div class="loadingPage" v-if="loading">
<h1>Veilliez patienter, conversion du fichier en cours .....</h1>
      <v-progress-circular
      class="mt-10"
      indeterminate
      color="black"
     :size="90"
     :width="10"
    ></v-progress-circular>
    </div>
    </div>
</template>
<script>
import uploadsFile from '../mixin/uploadFile'
import imagerezize from './Imagerezize.vue'
export default {
    props:['value','icon','size'],
  data: ()=>({
    image:{},
    dialog:false,
    idval:Math.floor(Math.random() * (9999999999999999999999999999999999 - 0 +1)) + 0,
    img:"https://images.pexels.com/photos/226746/pexels-photo-226746.jpeg",
    url:"",
    loading:false,
   
  }),
   mixins:[uploadsFile],
   components:{
imagerezize
   },
  mounted(){
      this.url = this.value
  },
  methods:{
    openFile()
    {
      let fileUpload = document.getElementById('fileUpload'+ this.idval)
  if (fileUpload != null) {
    fileUpload.click()
  }
    },
    remove()
    {
      this.url='/'
       this.$emit('input','/')
      
    },
      async Preview_image(e) {
        if(URL.createObjectURL(this.image))
          this.loading = true
   
       this.img =await this.convertFile(URL.createObjectURL(this.image),"website")
         this.loading = false;
       this.dialog = true;
      
     
    },
    changed(url)

    {
      
      this.url = url
      var self=this
      
      this.$nextTick(() => {
        self.$emit('input',this.url)
        
         self.$emit('change')
         self.dialog = false
  })
        
       
    }
  },
  watch:{
     
  }
}
</script>
<style scoped>
.loadingPage{
 position: fixed ;
 flex-direction:column;
 top:0px;
 left:0px;
 width: 100%;
 height: 100%;
 background: rgba(250, 250, 250, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}
.textInputMenu{
    background-color: rgba(0, 0, 0, 0.3); color: #FFFFFF; border: none; padding: 8px;
    outline: none;
   
    width: 100%;
height: 50px;
margin-bottom: 5px;
  }
  .textInputMenu:focus {
  border: 2px solid #157EBF;
}
</style>
