<template>
    <div class="text-center">
      <v-dialog
        v-model="dialog"
        width="500"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="red lighten-2"
            dark
            v-bind="attrs"
            v-on="on"
          >
            Click Me
          </v-btn>
        </template>
  
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Privacy Policy
          </v-card-title>
  
          <v-card-text>
            <ImagePicker v-model="ads_img" ></ImagePicker>
            <v-text-field label="Author text" v-model="ads_content" ></v-text-field>
            <v-text-field label="Author text" v-model="ads_link" ></v-text-field>
            <v-text-field label="Author text" v-model="ads_type" ></v-text-field>
            <v-text-field type="date" label="Author text" v-model="ads_date_end" ></v-text-field>
            
          </v-card-text>
  
          <v-divider></v-divider>
  
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="createads()"
            >
              I accept
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>
<script>
 import ImagePicker from './ImagePicker'
  import ConnectBackend from './ConnectBackend'

export default {
   
   props:["element"],
 data () {
     
   return {
     okgrad:false,
     dialog:false,
     ads_img:"",
     ads_date_end:"",
     ads_content:"",
     ads_link:"",
     ads_type:"",
   }
 },
 components: {
  
    ImagePicker
 },
 mounted(){
 

 },
 methods:{
    createads()
   {
    var element = {
        ads_img:this.ads_img,
     ads_date_end:this.ads_date_end,
     ads_content:this.ads_content,
     ads_link:this.ads_link,
     ads_type:this.ads_type,
     entreprise_id: JSON.parse(localStorage.entreprise).id
    }
     
     ConnectBackend.postAuth(localStorage.AU_2_MCD_42, element, '/backend/v1/ads_mcd/create' , this, function (
          ret) {

          if (ret.status == 201 || ret.status == 204 || ret.status == 200) {

            self.dialogADDtheme = false

          }
        })
   }
 }
 
}
</script>