<template>
 <div v-if="AU_2_MCD_42" >  <v-dialog
      v-model="dialog"
      persistent
      max-width="550"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Open Dialog
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="headline">
         Changer de compte
        </v-card-title>
        <v-card-text>

             <v-list subheader>
      <v-subheader>Compte Personnel</v-subheader>

      <v-list-item
      
      >
        <v-list-item-avatar>
          <v-img
            :alt="`${user.usr_name} avatar`"
            @click="selectUser()"
            :src="'http://localhost:3000/'+user.usr_img_url"
          ></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title @click="selectUser()" v-text="user.usr_first_name + ' ' + user.usr_name "></v-list-item-title>
        </v-list-item-content>

        <v-list-item-icon>
          
        </v-list-item-icon>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <v-list subheader>
      <v-subheader>Compte d'entreprise</v-subheader>

      <v-list-item
        v-for="(compte) in entreprises"
        :key="compte.ete_name"
      >
        <v-list-item-avatar>
          <v-img
          @click="SelectEntreprise(compte)"
            :alt="`${compte.ete_name} avatar`"
            :src="'http://localhost:3000/'+compte.ete_img_url"
          ></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title  @click="SelectEntreprise(compte)" v-text="compte.ete_name"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
           Fermer
          </v-btn>
        
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-navigation-drawer
      fixed
      permanent
      left
  app
    >
      <template v-slot:prepend>
        <v-list-item two-line class="mt-12">
          <v-list-item-avatar class="mt-12"> 
            <img :src="'http://localhost:3000/'+ (entreprise? entreprise.ete_img_url:user.usr_img_url)">
          </v-list-item-avatar>

          <v-list-item-content class="mt-12">
            <v-list-item-title>{{entreprise?entreprise.ete_name:user.usr_first_name + " "+ user.usr_name}}</v-list-item-title>
          
          </v-list-item-content>
        </v-list-item>
      </template>
    
     
      <v-divider></v-divider>

      <v-list dense>
        <v-list-item
          v-for="item in items"
          :key="item.title"
       
        >
       
             
         
        

          <v-list-item-content    v-if="item.pro || entreprise">
            
            <v-list-item-title class="d-flex align-center" @click="listAction(item)">
               <img :src="item.icon" width="40" > <h6 class="ml-3"> {{ item.title }} </h6></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
import  EventBus  from './event-bus';
  import {router } from '../router/index'
  import ConnectBackend from './ConnectBackend'
  export default {
    methods:{
      listAction(item)
      {
        item.title=='Changer de comptes'?this.dialog=true:this.$router.push('/'+item.url)
        
      },
      SelectEntreprise(entreprise)
      {
        this.entreprise =entreprise
        localStorage.entreprise =JSON.stringify(entreprise)
        this.dialog =false
      }
      ,selectUser()
      {
         this.entreprise =null
        localStorage.entreprise =null
        this.dialog =false
      }
    },
    mounted(){
      var self =this
      ConnectBackend.getAuth(localStorage.AU_2_MCD_42,'', '/api/v1//user/current' , self,function(ret)
      {
      
        self.user = ret.data
       
      } )
       ConnectBackend.getAuth(localStorage.AU_2_MCD_42,'','/backend/v1/getEntrerpiseUser' ,self, function(ret)
      {
        self.entreprises = ret.data
      } )
    var self = this 
    if(localStorage.entreprise!=null)
     this.entreprise = JSON.parse(localStorage.entreprise)
    else
      this.entreprise =  null
    EventBus.$on('SyncAU2',function(result){
    self.AU_2_MCD_42=localStorage.AU_2_MCD_42   
      ConnectBackend.getAuth(localStorage.AU_2_MCD_42,'', '/api/v1//user/current' , self,function(ret)
      {
       
        self.user = ret.data
      } )
       ConnectBackend.getAuth(localStorage.AU_2_MCD_42,'','/backend/v1/getEntrerpiseUser' , self,function(ret)
      {
        self.entreprises = ret.data
      } )   
    });

    },
    data () {
      return {
         AU_2_MCD_42:localStorage.AU_2_MCD_42,
         user:"",
        dialog:false,
        entreprise:"",
            entreprises: [

      ],
      previous: [{
        title: 'Travis Howard',
        avatar: 'https://cdn.vuetifyjs.com/images/lists/5.jpg',
      }],
        items: [
          { title: 'Home', icon: require('../assets/iconHome.png'),url:'WallUser' , pro:true },
        
          { title: 'Changer de comptes', icon: require('../assets/Compte.png')  , url: 'MyCompte' , pro:true},
          { title: 'Mon Compte', icon: require('../assets/iconuser.png' ), url: 'MyCompte', pro:true},
             { title: 'MarquettePlace', icon: require('../assets/Marketplace.png' ) , url: 'Shop' , pro:true} ,
          { title: 'Catalogue', icon: require('../assets/Catalogue.png' ) , url: 'ProductGest' , pro:false},
          { title: 'Création de Site', icon: require('../assets/iconweb.png' ) , url: 'myWebSite' , pro:false},
          { title: 'Création de Carte', icon: require('../assets/iconcart.png'), url: 'myCarte'  , pro:false},
       
        ],
      }
    },
  }
</script>