<template>
  <div class="about" v-if=" open">
      <v-card
    class="mx-auto overflow-hidden"
   
  >
    <v-app-bar

     color="rgba(193, 205, 222, 0.4)"
   style="border-radius:0 0 25px 25px; padding:0 5px;"
      lights-out
      fixed
    >
      <v-app-bar-nav-icon v-if="false" @click="drawer = true"></v-app-bar-nav-icon>

      <v-toolbar-title style="font-size:25px;border-weight:bold;">{{name}}</v-toolbar-title>
    </v-app-bar>

    
  </v-card>
  <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
          active-class=""
        >
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Account</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
   <div class="my-6" style="height:30Px"></div>
  </div>
</template>
<script>
import Event from './views/event-bus'
export default {
  data: ()=>({
    element:{},
    drawer:false,
    group:"",
    open:true,
    name:""
  }),
    mounted()
  {

      
    var self =this 
        Event.$on('Position',function(result){
           if(result=="editsite" | result=="log")
           self.open = false
           else{
             self.open = true 
           }
           if(result == "MyWebSite")
           self.name ="Mes Site"
           if(result == "setting")
            self.name ="Paramettre"
           
     
    });
  }
}
</script>
