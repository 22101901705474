<template>
  <div class="overlay" v-if="cookiesOn">
    <div class="cookiesPup px-5 pt-3">
        <p class="mr-2" style="color:rgba(0,0,0,0.7) ;"> Ce site web utilise des cookies.</p>
       

   <v-dialog
      v-model="dialog"
      width="550"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="grey"
          dark
          small
          rounded
          v-bind="attrs"
          v-on="on"
        >
          préférence
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 ">
         Personnaliser les préférences sur les cookies
        </v-card-title>

        <v-card-text>
          <p>

Les cookies nous permettent de personnaliser le contenu , d'offrir des fonctionnalités relatives aux médias sociaux et d'analyser notre trafic. 
Nous partageons également des informations sur l'utilisation de notre site avec nos partenaires de médias sociaux, de publicité et d'analyse,
qui peuvent combiner celles-ci avec d'autres informations que vous leur avez fournies ou qu'ils ont collectées lors de votre utilisation de leurs services.
</p>
             <v-expansion-panels class="mt-5">
    <v-expansion-panel
    
    >
      <v-expansion-panel-header class="subtitle-2 ">
     Cookies nécessaires au fonctionnement du site 
      </v-expansion-panel-header>
      <v-expansion-panel-content >
         Les cookies nécessaires contribuent à rendre un site web utilisable en activant des fonctions de base comme la navigation de page et l'accès aux zones sécurisées du site web. Le site web ne peut pas fonctionner correctement sans ces cookies.
      <div class="my-2"   style="width:90px;"> <v-btn
            color="primary"
           
            small
          
            @click="(more?more=false:more=true)"
          > En savoir plus </v-btn></div>
            <div v-if="more">
       <p> Nous utilisons des cookies pour fournir nos services pour par exemple :</p>
       <ul>
<li>Vous reconnaître lorsque vous vous connectez pour utiliser nos services.</li>
<li>Vous reconnaître en tant que membre MCD et vous proposer d’autres fonctionnalités et services personnalisés.</li>
<li>Afficher des fonctionnalités, des produits et des services qui pourraient vous intéresser  et services disponibles sur MCD</li>
<li>Conserver le suivi des éléments enregistrés dans votre panier.</li>
<li>Prévenir les activités frauduleuses.</li>
<li>Améliorer la sécurité.</li>
<li>Assurer le suivi de vos préférences, comme celles sur la devise et la langue.</li>
</ul>
</div>
     </v-expansion-panel-content>
    </v-expansion-panel>
       <v-expansion-panel
    
    >
      <v-expansion-panel-header class="subtitle-2 ">
     Cookies publicitaires, statistiques et marketing 
      </v-expansion-panel-header>
      <v-expansion-panel-content >

       <ul>
        <li>  les cookies publicitaires nous permettent de vous proposer des publicités ,
         notamment des publicités correspondant à vos centres d'intérêt, et de collaborer avec des tiers approuvés dans le cadre du processus de diffusion de contenu, 
         afin de mesurer l'efficacité des publicités et d'effectuer des services pour le compte MCD.</li>
        <li>  Les cookies statistiques aident les propriétaires du site web, par la collecte et la communication d'informations de manière anonyme, à comprendre comment les visiteurs interagissent avec les sites web.</li>
         <li> Les cookies marketing sont utilisés pour effectuer le suivi des visiteurs au travers des sites web. Le but est d'afficher des publicités qui sont pertinentes et intéressantes pour l'utilisateur individuel et donc plus précieuses pour les éditeurs et annonceurs tiers.</li>
     </ul>
     <div class="my-2"   style="width:90px;"> <v-btn
            color="grey"
           
            small
            dark
            @click="(more?more=false:more=true)"
          > Personaliser les cookies </v-btn></div>
            <div v-if="more" class="mx-2">
      
 <v-switch
  inset
    
    v-model="CookiesPref.cookMCDPub"
      label="Cookies publicitaires MCD globale systems."
    ></v-switch>
     <v-switch
       inset
  
  v-model="CookiesPref.cookTiersPub"
      label="Cookies publicitaires tiers approuvés"
    ></v-switch>
          
 <v-switch
   inset
   
  v-model="CookiesPref.cookMCDStat"
      label="Cookies statistiques MCD globale systems."
    ></v-switch>
     <v-switch
       inset
    
  v-model="CookiesPref.cookTiersStat"
      label="Cookies statistiques tiers approuvés"
    ></v-switch>
          
 <v-switch
   inset
 
  v-model="CookiesPref.cookMCDMark"
      label="Cookies marketing MCD globale systems."
    ></v-switch>
     <v-switch
       inset

 v-model="CookiesPref.cookTiersMark"
      label="Cookies marketing tiers approuvés"
    ></v-switch>
 <v-btn
           
            color="grey"
            small
            dark
            @click="savepref()"
          > Sauvegarder les préférences</v-btn>
          


</div>
     </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
      
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            @click="dialog = false"
          >
            close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
     <v-btn
           dark
           rounded
            class="ml-3"
            small
            @click="setcookies()"

          >
           accepter
          </v-btn>
    </div>
  </div>
</template>
<script>
export default {
  data: ()=>({
    element:{},
    dialog:false,
    more:false,
    cookiesOn:true,
    CookiesPref:{}
  }), created(){
    
    if(!localStorage.CookiesPref || localStorage.CookiesPref =="undefined"){
        this.CookiesPref ={
            cookMCDPub:true,
            cookTiersPub:true,
            cookMCDStat:true,
            cookTiersStat:true,
             cookMCDMark:true,
            cookTiersMark:true,

        }
    }
    else{
      this.CookiesPref = JSON.parse(localStorage.CookiesPref)
    }
    if(localStorage.cookie2 && localStorage.CookiesPref !="undefined" && localStorage.CookiesPref )
    {
      this.cookiesOn = false
     
    }
    else{
      this.cookiesOn = true
    }

  },methods:{
    savepref(){
     
      localStorage.CookiesPref = JSON.stringify(this.CookiesPref)
      
      this.setcookies()
    },
    setcookies(){
      this.cookiesOn = false
      localStorage.CookiesPref = JSON.stringify(this.CookiesPref)
      localStorage.cookie2 = true
    }
  }
  }
</script>
<style scoped> 

.cookiesPup{
    position: fixed;
    display: flex;
    box-shadow: 0px 0px 10px black;
    left:0;
    right: 0;
    width: 487px;
    margin: 0 auto;
    bottom:20px;
  
    background: white;
    border-radius:600px;
}
.overlay{
    position: fixed;
    left:0;
    right: 0;
    bottom:0;
    margin: 0 auto;
    z-index: 9999;
}
</style>
