import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'
import VueQuillEditor from 'vue-quill-editor'

import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme

Vue.use(require('vue-script2'))

import ConnectBackend from './views/ConnectBackend'
Vue.use(VueQuillEditor, /* { default global options } */ )
//Vue.prototype.$domainName = "https://alphaback.mcd-global-system.be"
//Vue.prototype.$domainName = "http://localhost:3500"
Vue.prototype.$domainName = "https://mcd.m-code.net/"
Vue.prototype.$lastColor = []

import VueDraggableResizable from 'vue-draggable-resizable'

// optionally import default styles
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'

Vue.component('vue-draggable-resizable', VueDraggableResizable)

var VueCookie = require('vue-cookie');
// Tell Vue to use the plugin
Vue.use(VueCookie);

Vue.config.productionTip = false



Vue.prototype.$website = {
        pages: [
            { titre: "Home", url: "home", blocks: [] },
        ],
        header: {
            background: "green",
            flat: true,
            dense: true,
            logo: "https://logo-marque.com/wp-content/uploads/2020/04/Huawei-Logo.png",
            titreText: "Mon Supter Site",
            titreColor: "red",
            titreFont: "serif",
            titreSize: "20",
            titreWeight: "bold",
            menuColor: "grey",
            menuFont: "serif",
            menuSize: "20",
            menuWeight: "bold",
            center: true,
        },
        footer: {
            entreprise: "MAT Traiteur",
            text: "zrqetsdymrujgqhetqgmjtrgqorgsi<ugmrsergkm",
            background: "red",

            textColor: "red",
            textFont: "serif",
            textSize: "20",
            textWeight: "bold",
            menuColor: "grey",
            menuFont: "serif",
            menuSize: "20",
            menuWeight: "bold",
        }
    },
    new Vue({
        vuetify,
        router,
        store,
        render: h => h(App)
    }).$mount('#app')