<template>
<div >
<div v-if="openChat" class="MobileAPP shadow-lg">
    <div style="background:#1E1E1E;width:320px; height:56px; position:fixed"></div>
  <h5  v-if="!currentMatch.id" class="m-3"  style="color:white;z-index:20px;position:relative" >Mes Contacts</h5>
  <v-btn icon :elevation="3" class="btclose"> <v-icon @click="openChat=false" dark>mdi-close</v-icon> </v-btn>
  <div class="blockcontact" style="  overflow: hidden; ">
    <div v-if="!currentMatch.id">
    <v-text-field
    class="mx-3 mt-2"
            label="Rechercher"
            placeholder="Rechercher"
            filled
            small
            rounded
            dense
          ></v-text-field>
          <v-list subheader style="margin-top:-20px">
      <v-subheader>Demande d'ajout</v-subheader>

      <v-list-item
        v-for="chat in myView"
      
      >
        <v-list-item-avatar>
        <v-img
            :alt="`${chat.dtp_pseudo} avatar`"
            :src="JSON.parse(chat.dtp_imgs)[0]"
          ></v-img>
          
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title v-text="chat.dtp_pseudo"></v-list-item-title>
        
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon color="success" @click=" matchok(chat.id)">
            mdi-check
          </v-icon>
          <v-icon class="ml-2" color="error"  @click=" matchnop(chat.id)">
            mdi-close
          </v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
  <v-list subheader>
  
   
          <v-subheader>Contacts</v-subheader>
        
          <v-list-item v-for="chat in mymatch" :key="chat.id"  >
          <v-list-item-avatar>
            <v-img :alt="`${chat.dtp_pseudo}`" :src="JSON.parse(chat.dtp_imgs)[0]"></v-img>
          </v-list-item-avatar>

          <v-list-item-content class="d-flex">
            <v-list-item-title v-text="chat.dtp_pseudo"></v-list-item-title>

            <div v-if="chat.noview=='True' && chat.mrh_last_sender!= profile.id">
              <v-alert class="alertNotif" color="rgb(255, 193, 7)">
                <v-icon style="font-size:10px;color:white">mdi-chat</v-icon>
              </v-alert>
            </div>
          </v-list-item-content>
          <v-list-item-icon>
       
          <v-icon class="ml-2" color="black" @click="matchConversation(chat)">
            mdi-chat
          </v-icon>
        </v-list-item-icon>
        </v-list-item>
    </v-list>
   </div>
    <div v-else style="  overflow: hidden; ">
    <div style="  overflow: hidden; ">
      <div class="d-flex mx-3 mt-3" style="  overflow: hidden; ">
      <v-icon  @click="currentMatch=false"   style="color:white;z-index:20px;position:relative;font-size: 25px;" class="marginDraw" >mdi-arrow-left
        </v-icon>
        <div>
        <v-img class="ml-2" style="width: 30px; height: 30px; border-radius: 50px;" :alt="`${currentMatch.dtp_pseudo}`" :src="JSON.parse(currentMatch.dtp_imgs)[0]"></v-img>
      </div>
        <h5 class="mt-1 ml-3"  style="color:white;z-index:20px;position:relative">{{this.currentMatch.dtp_pseudo}}</h5>
      </div>
      <div class=" messages" id="message">
        <div class=" messagesBlock">
          <div v-for="m in  messages"
            :class="'d-flex' + (m.dating_profil_id==profile.id?' justify-end':' justify-start')">

            <v-chip  class="ma-2 " :color="m.dating_profil_id==profile.id?'green':'primary'" text-color="white">
              {{m.mtm_content}}

              <v-icon v-if="m.dating_profil_id==profile.id" @click="messageremove(m.id)">mdi-close</v-icon>
            </v-chip>
          </div>
        </div>
      </div>
    </div>
    <div class="textinput-message" >

<v-text-field v-model="message" :append-outer-icon=" 'mdi-send' " :prepend-icon="icon" solo
  clear-icon="mdi-close-circle" clearable label="Message" type="text"
  @click:append-outer="messagecreate(profile.id)"></v-text-field>
</div>
</div>
</div>

  <div class="dockMessage" v-if="false">
    <v-btn  fab >
    <v-icon style="color:black;font-size:30px">mdi-account-multiple</v-icon>
    </v-btn>
    <v-btn icon >
    <v-icon style="color:white;font-size:30px">mdi-magnify</v-icon>
</v-btn>
    <v-btn icon class="p-2">
    <v-icon style="color:white;font-size:30px" >mdi-chat</v-icon>
    <div class="notif">2</div>
    </v-btn>
  </div>

</div>
<div class="notificationElement" v-if="messageNotif">
<v-alert  v-model="alert" color="rgb(255, 193, 7)" border="left"
        elevation="2" colored-border>
        vous avez reçus un nouveau message de {{messageNotif.author}}
      </v-alert></div>
    <v-btn
      class="mx-2 btopen"
      fab
      @click="openChat=true"
      dark
     
    >
      <v-icon dark>
        mdi-chat
      </v-icon>
    </v-btn>
   
</div>
</template>
<script> 
import ConnectBackend from './ConnectBackend'
import ActionCable from 'actioncable'
  export default {
    props:['profile'],
    data: () => ({
      messageNotif:{author:""},
      openChat:false,
      myView:[],
      
      alert:false,
      openMessage: false,
      currentMatch:{},
      connection: "",
    
      openMessage:false,
      messages:"",
      roomConnections: [],
    
      mymatch:[],
      recent: [
        {
          
          avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
          title: 'Jason Oner',
        },
        {
          
          avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
          title: 'Mike Carlson',
        },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg',
          title: 'Cindy Baker',
        },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/4.jpg',
          title: 'Ali Connors',
        },
      ],
      previous: [{
        title: 'Travis Howard',
        avatar: 'https://cdn.vuetifyjs.com/images/lists/5.jpg',
      }],
    }),
    mounted() {
     
      this.GetLike()
      this.getmatch()
      this.ChatConnection()
    },
    methods: {
      talk(message, roomId) {
        let roomConnObj = this.roomConnections.find(conn => conn.roomId == roomId)
        if (roomConnObj) {
          roomConnObj.conn.speak(message)
        } else {
          console.log('Error: Cannot find room connection')
        }
      },
      openNewRoom(roomId) {
        if (true) {
          this.roomConnections.push({
            roomId: roomId,
            conn: this.createRoomConnection(roomId)
          })
        }
      },
      disconnect() {
        this.roomConnections.forEach(c => c.conn.consumer.connection.close())
      },
      createRoomConnection(room_code) {
        var scope = this
        var self = this
        return this.connection.subscriptions.create({
          channel: 'RoomChannel',
          room_id: room_code,
          sender: this.profile.id,
          token: localStorage.AU_2_MCD_42
        }, {
          connected: function () {
            console.log('connected to RoomChannel. Room code: ' + room_code + '.')
          },
          disconnected: function () {},
          received: function (data) {
            if (data.sender != self.profile.id) {
              if (self.currentMatch.id == data.room_id && self.messages[self.messages.length - 1].id !== data
                .id) {

                self.messages.push({
                  dating_profil_id: data.sender,
                  mtm_content: data.content,
                  id: data.id
                })
              } else {
                self.messageNotif = {
                  author: data.author,
                  match: data.match
                }
                self.messageNotif.match.dtp_pseudo = data.author
                self.alert = true
                const index = self.mymatch.findIndex(object => {
                  return object.id === data.room_id;
                });
                self.mymatch[index].noview = "True"
                setTimeout(function () {
                  self.messageNotif = null
                }, 10000)
              }
            }
          },
          speak: function (message) {
            return this.perform('speak', {
              room_id: room_code,
              message: message,
              sender: scope.senderId
            })
          }
        })
      },
      ChatConnection(senderId, callback) {


        var wsUrl = 'ws://51.68.125.86:3500/cable'
        wsUrl += '&client=' + this.profile



        this.connection = ActionCable.createConsumer(wsUrl)
        this.roomConnections = []
      },
      view_message(id) {

var self = this
ConnectBackend.pathAuth(localStorage.AU_2_MCD_42, "", '/backend/v1/match/view_message/' + id + '/' + this
  .profile.id,
  self,function (ret) {

    self.getmatch()
  })
},
      matchnop(id) {
        var self = this
        ConnectBackend.postAuth(localStorage.AU_2_MCD_42, this.profile, '/backend/v1/match/matchnop/' + id,self, function (
          ret) {
          self.getmatch()
        })
      },
      matchok(id) {
        var self = this
        ConnectBackend.postAuth(localStorage.AU_2_MCD_42, this.profile, '/backend/v1/match/matchok/' + id, self, function (
          ret) {
          self.getmatch()

        })
      },
      GetVueprof()
    {
var self = this
        ConnectBackend.getAuth(localStorage.AU_2_MCD_42, {}, '/backend/v1/DatingProfile/View/' + this.profile.id, self, function (
          ret) {
         self.myView = ret.data

        })
      
    },
    GetLike()
    {
var self = this
        ConnectBackend.getAuth(localStorage.AU_2_MCD_42, {}, '/backend/v1/match/myLike/' + this.profile.id, self, function (
          ret) {
            self.myLike =  ret.data

        })
      
    },
      getmatch() {
        var self = this

        ConnectBackend.getAuth(localStorage.AU_2_MCD_42, this.profile, '/backend/v1/match/get/' + self.profile.id, self,
          function (ret) {
            self.disconnect()
            self.mymatch = ret.data
           
           
           
            self.mymatch.forEach(element => self.openNewRoom(element.id));

          })
      },
      matchConversation(chat) {
        this.currentMatch = chat
        this.getmessage(chat.id)
        this.openMessage = true

      },
      messagecreate(id) {
        var self = this
        ConnectBackend.postAuth(localStorage.AU_2_MCD_42, {
          mtm_content: this.message
        }, '/backend/v1/dating_messages/create/' + id + '/' + this.currentMatch.id, self, function (ret) {
          self.getmessage(self.currentMatch.id)
        })
      },
      getmessage(id) {
        var self = this
        ConnectBackend.getAuth(localStorage.AU_2_MCD_42, "", '/backend/v1/dating_messages/get/' + id, self, function (ret) {
          self.view_message(id)
          self.messages = ret.data
          var element = document.getElementById("message");
          element.scrollTop = element.scrollHeight;

        })
      },
      messageremove(id) {
        var self = this
        ConnectBackend.postAuth(localStorage.AU_2_MCD_42, "", '/backend/v1/dating_messages/disable/' + id, self, function (
          ret) {
          self.getmessage(self.currentMatch.id)
          self.openMessage = false
        })
      }
    },
    
  }
</script>
<style >
.btclose{
    position: absolute;
    top:10px;
    background: white;
    right: 15px;
}
.btopen{
    position:fixed;
 bottom:10px;
 right:10px;   
}
.MobileAPP{
 position:fixed;
 bottom:20px;
 right:20px;
 height:550px;
 width: 320px;
 background:white;
 
 border-radius:0px;
 z-index: 99;
}
.dockMessage{

 position:absolute;
 bottom:0px;
 height:50px;
 width: 320px;
 border-radius: 0 20px;
 background:black;
 display: flex;
 justify-content: space-around;
 align-items: center;
box-shadow: 0px -1px 5px gray;
}
.selectblock{

}
.blockcontact{
    overflow: scroll;
    height: 450px;
}
.notif{
    color:white;
    background: red;
    border-radius: 30px;
    padding: 0px 3px;
    position: relative;
    left: -5px;
    top:-5px;
}
.btFiltre{
  position: fixed;
  z-index: 999;
  top:70px;
  margin-left: 350px;
}
.MenuNavDate{
  position: fixed;
  bottom: 0px;
  z-index: 999;
  
}
 

  .notificationElement {
    position: fixed;

    margin: 0 auto;
    top: 70px;
    left: 10px;
    right: 0px;
    z-index: 99999;
    display: flex;
    justify-content: center;
  }

  .notificationBlock {
    padding: 10px 20px;
    border-radius: 50px;
    background: rgba(255, 255, 255, 0.8);
    cursor: pointer;
  }

  .marginDraw {
    margin-left: 0px;
  }

  .messages {
    margin-left: 10px;
    margin-top: 10px;
    height: 390px;
    overflow-y: scroll;
    overflow-x: none;

  }

  .messages:hover {
    margin-left: 10px;
    margin-top: 10px;
    height: 390px;

    overflow-y: scroll;
    overflow-x: none;
  }

  .messagesBlock {}

  .addbt {
    background: #4CAF50;
    justify-content: center;
    align-items: center;
    display: flex;
    min-width: 80px;
  }

  .ZoneSwipe {

    padding-top: 50px;
    height: 90%;
    display: flex;
    justify-content: center;
    margin: 50px auto;
    overflow: hidden;
  }

  .swiper {
    position: absolute;
  }

  .imgAdd {
    width: 80px;
    height: 120px;
    border-radius: 10px;
    object-fit: cover;
  }

  .swipercard {
    position: absolute;
    width: 250px;
    font-size: 25px;
    text-align: center;

  }

  .textinput-message {
    position: absolute;
    bottom: -10px;
    left: 20px;
    right: 20px;
  }

  .alertNotif {
    border-radius: 50% !important;

    padding: 0px 5px 2px 5px !important;
    position: absolute !important;
    top: 35px;
    left: 45px;
  }

  .nomobile {
    display: block;
  }

  .logoDating {
    width: 50px;
  }

  .nodesk {
    display: none !important;
  }

  @media (max-width: 1000px) {
    .nodesk {
      display: block !important;
    }

    .messages {
      margin-left: 0px;
      margin-top: 0px;
      height: 75vh;
      overflow-y: scroll;

    }

    .marginDraw {
      margin-left: 0px;
    }

    .nomobile {
      display: none;
    }

    .textinput-message {

      left: 30px;

    }

    .logoDating {
      width: 35px;
    }

   
  }
</style>