import ConnectBackend from '../views/ConnectBackend'
export default {

    methods: {
        async postImage(image, type) {

            if (localStorage.entreprise) {

                return await this.postImageEntreprise(image, type)

            } else {
               
                return await this.postImageUser(image, type)
            }
        },
        async postVideo(image, type) {
          
            if (localStorage.entreprise) {

                return await this.postVideoEntreprise(image, type)

            } else {
                
                return await this.postVideoUser(image, type)
            }
        },
        async postImageEntreprise(image, type) {
            var ret_val = {}
           var  result = this.image
            var image = { fud_name: type, fud_type: type, fud_url: result }
            var self = this
            ret_val = await new Promise((resolve, reject) => {
                ConnectBackend.postAuth(localStorage.AU_2_MCD_42, image, '/backend/v1/entreprises/' + JSON.parse(localStorage.entreprise).id + '/upload-image',self, function(ret) {

                    resolve(self.$domainName+'/' + ret.data)
                    console.log(ret_val)


                })
            })
            console.log(ret_val)
            return ret_val
        },
        async postImageUser(image, type) {
            var ret_val = {}
            
            var result = this.image
            var image = { fud_name: type, fud_type: type, fud_url: result }
            var self = this
            ret_val = await new Promise((resolve, reject) => {
                ConnectBackend.postAuth(localStorage.AU_2_MCD_42, image, '/api/v1/user/upload-image', self,function(ret) {

                    resolve(self.$domainName +'/'+ ret.data)
                    console.log(ret_val)


                })
            })
            console.log(ret_val)
            return ret_val


        },
        async postVideoEntreprise(image, type) {
            var ret_val = {}
            const reader = new FileReader();

            reader.readAsDataURL(this.image);

            const result = await new Promise((resolve, reject) => {
                reader.onload =

                    function(event) {
                        resolve(reader.result)
                    }
            })
            console.log(result)
            var image = { fud_name: type, fud_type: type, fud_url: result }
            console.log(type)
            var self = this
            ret_val = await new Promise((resolve, reject) => {
                console.log("o4454k")
                ConnectBackend.postAuth(localStorage.AU_2_MCD_42, image, '/backend/v1/entreprises/' + JSON.parse(localStorage.entreprise).id + '/upload-video', self, function(ret) {
                    console.log("ok")
                    resolve(self.$domainName +'/'+ ret.data)
                    console.log(ret_val)


                })
            })
            console.log(ret_val)
            return ret_val
        },
        async postVideoUser(image, type) {
            var ret_val = {}
            const reader = new FileReader();

            reader.readAsDataURL(this.image);

            const result = await new Promise((resolve, reject) => {
                reader.onload =

                    function(event) {
                        resolve(reader.result)
                    }
            })
            console.log(result)
            var image = { fud_name: type, fud_type: type, fud_url: result }
            var self = this
            ret_val = await new Promise((resolve, reject) => {
                ConnectBackend.postAuth(localStorage.AU_2_MCD_42, image, '/api/v1/user/upload-video', self, function(ret) {

                    resolve(self.$domainName +'/'+ ret.data)
                    console.log(ret_val)


                })
            })
            console.log(ret_val)
            return ret_val


        },
        async convertFile(image, type) {
            var ret_val = {}
            const reader = new FileReader();

            reader.readAsDataURL(this.image);

            const result = await new Promise((resolve, reject) => {
                reader.onload =

                    function(event) {
                        resolve(reader.result)
                    }
            })
            var image = { fud_name: type, fud_type: type, fud_url: result }
         
            var self = this
            ret_val = await new Promise((resolve, reject) => {
                ConnectBackend.postAuth(localStorage.AU_2_MCD_42, image, '/backend/v1/websites/convert_file_to_jpg', self, function(ret) {

                    resolve(self.$domainName+'/' + ret.data)
                    console.log(ret_val)


                })
            })
            console.log(ret_val)
            return ret_val
        },
    }

}