<template>
<div>
     <v-badge v-if="notifImportant == true"
          bordered
          color="red"
          :content="notifNumber"
           :value="notifNumber"
           overlap
          
        >
     
        <v-icon color="black"
                style = "font-size:40px;"
        
        @click.stop="drawer = !drawer ;">mdi-bell</v-icon>
       </v-badge>
      <v-badge v-else
          bordered
          color="green"
          :content="notifNumber"
           :value="notifNumber"
           overlap
          
        >
        <v-icon color="black"
                style = "font-size:40px;" 

        @click.stop="drawer = !drawer ; ">mdi-bell</v-icon>
       </v-badge>

      <v-navigation-drawer
        v-model="drawer"
        absolute
        temporary
        right
        dark
      >
        <v-list-item>

          <v-list-item-content>
            <v-list-item-title>Notifications</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
  
        <v-divider></v-divider>
  
        <v-list dense>
          <v-list-item
            v-for="notification in notifications"
            :key="notification.id"
            link
> 
          
            <v-list-item-content>
            <v-list-item-title class="notif" v-if="notification.noti_important == true && notification.noti_seen ==false" @click="UpdateNotifs(notification)" >
                  <v-icon v-if="notification.noti_seen==false">mdi-circle-small</v-icon>
                  {{ notification.noti_content }}
                </v-list-item-title>
              <v-list-item-title v-else @mouseover="UpdateNotifs(notification)">
                  <v-icon v-if="notification.noti_seen==false">mdi-circle-small</v-icon>
                  {{ notification.noti_content }}
                </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      </div>
</template>

<script>
import ConnectBackend from '../views/ConnectBackend'

export default {
    name:"Notification",
    props:{drawer:Boolean , notifications:Array , notifNumber:Number , notifImportant:Boolean},
    data () {
    return {

    }
    
  },
   methods : {
    UpdateNotifs: function(notif){
      if(notif.noti_seen != true){
        notif.noti_seen = true;
        console.log(notif);
        this.notifNumber = 0,
      this.notifImportant =false
      this.notifications.forEach(notification => {
      if(notification.noti_seen ==false){
        this.notifNumber += 1
        if(notification.noti_important ==true){
        this.notifImportant =true
        }
        }
        })
        this.UpdateDatabaseSeenStatus(notif)
        }
    },

    UpdateDatabaseSeenStatus(notif){
        var self = this

        ConnectBackend.pathAuth(localStorage.AU_2_MCD_42, '' ,'/backend/v1/notifications/update-seen-status/'+ notif.id,function(ret){
       

        },function(){
          self.error=true
        }) 
    },

  }


}
</script>

<style>
.notif{
    color: #E53935;
}

</style>